import { CurrencyDollarIcon, EyeIcon } from '@heroicons/react/24/outline'
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth'
import startOfToday from 'date-fns/startOfToday'
import * as React from 'react'
import isWeekend from 'date-fns/isWeekend'
import isSameDay from 'date-fns/isSameDay'
import isTodayFn from 'date-fns/isToday'
import addYears from 'date-fns/addYears'
import addDays from 'date-fns/addDays'
import isAfter from 'date-fns/isAfter'
import isPast from 'date-fns/isPast'
import format from 'date-fns/format'

import { MAX_PTO_REQUEST_RANGE } from '../config'
import {
  TimeOffRequestPreview,
  TimeOffTransaction,
  TimeOffStatus,
  Holiday,
} from '../types'
import { classNames } from '../utils'
import useProfile from '../hooks/useProfile'
import Tooltip from './Tooltip'

export const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  // @ts-ignore
  trailingZeroDisplay: 'stripIfInteger',
})

type CellStatus = TimeOffStatus | 'off-range' | 'none'

export function getCellStatus({
  selectionEntries,
  transactions,
  day,
}: {
  selectionEntries: [string, boolean][]
  transactions: TimeOffTransaction[]
  day: Date
}): CellStatus {
  // check if cell date is included in an `approved` timeoff request
  if (transactions.some((t) => t.type === 'debit' && t.status === 'Approved')) {
    return 'Approved'
  }

  // check if cell date is included in an `pending` timeoff request
  if (
    transactions.some(
      (t) => t.type === 'debit' && t.status === 'Waiting for approval'
    )
  ) {
    return 'Waiting for approval'
  }

  // check if cell date would exceed the maximum request range
  if (
    selectionEntries.some(([timestamp]) =>
      isAfter(day, addDays(parseInt(timestamp, 10), MAX_PTO_REQUEST_RANGE))
    )
  ) {
    return 'off-range'
  }

  return 'none'
}

function getCellColors(
  selectionStatus: Props['selectionStatus'],
  cellStatus: Props['status'],
  holiday: Props['holiday']
): string {
  return classNames(
    cellStatus === 'Approved' && 'bg-green-300 disabled:bg-green-100',
    cellStatus === 'Waiting for approval' &&
      'bg-orange-100 disabled:bg-orange-50',
    cellStatus === 'none' && 'bg-slate-50 disabled:bg-white hover:bg-slate-200',
    cellStatus === 'off-range' &&
      'bg-slate-50 disabled:bg-white hover:bg-slate-200',
    cellStatus === 'Canceled' &&
      'bg-slate-50 disabled:bg-white hover:bg-slate-200',
    selectionStatus === 'unselected'
      ? 'outline outline-1'
      : 'rounded-md outline-dashed outline-2 outline-offset-[-3px]',
    selectionStatus === 'unselected' && 'outline-slate-300',
    selectionStatus === 'covered' &&
      'outline-sky-600 !bg-sky-100 hover:!bg-sky-100/80 !text-sky-700',
    selectionStatus === 'not-covered' &&
      'outline-red-600 !bg-red-200 hover:!bg-red-200/80 !text-red-800',
    !!holiday && '!bg-blue-200 !disabled:bg-blue-50'
  )
}

type Props = {
  datePreviewData?: TimeOffRequestPreview
  selectionStatus: 'unselected' | 'covered' | 'not-covered'
  transactions: TimeOffTransaction[]
  isDisabled: boolean
  onClick(): void
  holiday?: Holiday
  status: CellStatus
  day: Date
}

export default function CalendarCell(props: Props) {
  const profile = useProfile()
  const isToday = isTodayFn(props.day)

  const isStartingDate =
    !!profile.data?.startingDate &&
    isSameDay(profile.data.startingDate, props.day)

  const isDisabled =
    props.isDisabled ||
    isWeekend(props.day) ||
    isToday ||
    isPast(props.day) ||
    isAfter(props.day, addYears(startOfToday(), 1)) ||
    props.status !== 'none' ||
    !!props.holiday

  const credits = props.transactions.filter(
    (t) =>
      t.type === 'credit' &&
      (t.status === 'Approved' || t.status === 'Waiting for approval')
  )

  const isUnderReview = props.transactions.some(
    (t) => t.status === 'Waiting for approval' && t.underReview
  )

  return (
    <button
      aria-checked={props.selectionStatus !== 'unselected'}
      role="switch"
      type="button"
      onClick={props.onClick}
      className={classNames(
        'group flex aspect-[7/6] flex-col items-center justify-center disabled:cursor-not-allowed',
        getCellColors(props.selectionStatus, props.status, props.holiday)
      )}
      disabled={isDisabled}
    >
      <div className="flex h-6 self-start">
        {isStartingDate && (
          <Tooltip content="Your starting date with us">
            <div className="cursor-help p-1 text-xs sm:p-2 md:text-sm">🚀</div>
          </Tooltip>
        )}
        {credits.length > 0 && (
          <div className="p-1 text-left sm:p-2">
            {credits.map((t) => (
              <Tooltip
                content={
                  t.employeeBought ? 'Not covered by PTO policy!' : t.detail
                }
                key={t._id}
              >
                <div
                  className={classNames(
                    'cursor-help text-[11px] font-semibold [letter-spacing:-0.5px]  sm:text-xs',
                    t.employeeBought ? 'text-red-500' : 'text-green-600'
                  )}
                >
                  {t.employeeBought ? (
                    typeof t.dayValue === 'number' ? (
                      currencyFormat.format(t.dayValue * -1)
                    ) : (
                      <CurrencyDollarIcon className="inline h-4 w-4" />
                    )
                  ) : (
                    <>
                      +{t.amount}
                      <span className="hidden sm:inline"> PTO</span>
                    </>
                  )}
                </div>
              </Tooltip>
            ))}
          </div>
        )}
      </div>

      <div className="flex grow items-center justify-center self-stretch">
        <div
          className={classNames(
            isToday
              ? 'rounded-full bg-red-500 text-white'
              : props.selectionStatus !== 'unselected'
              ? 'text-inherit'
              : 'text-slate-700 group-disabled:text-slate-500',

            'min-w-[28px] whitespace-nowrap px-2 py-1 text-sm sm:min-w-[36px] sm:text-lg'
          )}
        >
          {format(props.day, 'd')}
          {isFirstDayOfMonth(props.day) && (
            <>
              <span className="ml-1 text-xs lg:hidden">
                {format(props.day, 'MMM')}
              </span>
              <span className="ml-1 hidden text-xs lg:inline">
                {format(props.day, 'MMMM')}
              </span>
            </>
          )}
        </div>
      </div>

      <div className="h-6 px-1 text-xs text-slate-900 lg:font-semibold">
        <span>
          {
            {
              Rejected: 'Rejected',
              Canceled: 'Canceled',
              Approved: (
                <span className="text-slate-500">
                  Appr<span className="sr-only lg:not-sr-only">oved</span>
                </span>
              ),
              'Waiting for approval': isUnderReview ? (
                <Tooltip content="This request has been seen by an admin and is currently under review">
                  <span>
                    <EyeIcon className="relative -left-0.5 -top-px inline-block h-3 w-3" />
                    <span className="not-sr-only text-slate-500 xl:sr-only">
                      Pending
                    </span>
                    <span className="sr-only text-slate-500 xl:not-sr-only">
                      Under review
                    </span>
                  </span>
                </Tooltip>
              ) : (
                <>
                  Pending{' '}
                  <span className="sr-only xl:not-sr-only">Approval</span>
                </>
              ),
              'off-range': '',
              none: '',
            }[props.status]
          }
        </span>
        {props.holiday && (
          <Tooltip content={props.holiday.name}>
            <span className="text-slate-600">
              <span className="sr-only lg:not-sr-only">National</span> Holiday
            </span>
          </Tooltip>
        )}

        {props.selectionStatus === 'not-covered' && (
          <Tooltip content="Not enough PTO up to this date. This would be deducted from your monthly payment.">
            {typeof props.datePreviewData?.dayValue === 'number' ? (
              <span className="text-red-700">
                <span className="sr-only lg:not-sr-only">Cost: </span>
                {currencyFormat.format(props.datePreviewData.dayValue)}
              </span>
            ) : (
              <CurrencyDollarIcon className="-mt-1.5 inline-block h-5 w-5 text-red-600" />
            )}
          </Tooltip>
        )}
      </div>
    </button>
  )
}
