import {
  ComboboxOptions,
  ComboboxOption,
  ComboboxInput,
  Combobox,
} from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline'
import { Controller, useFormContext } from 'react-hook-form'
import FuzzySearch from 'fuzzy-search'
import * as React from 'react'

import useAccountAliasValidate from '../hooks/useAccountAliasValidate'
import { classNames } from '../utils'
import { Account } from '../types'
import TextField from './TextField'

const required = 'This field is required'

const banks = [
  { key: 'bofa', value: 'Bank of America', content: 'Bank of America' },
  { key: 'chase', value: 'Chase Bank', content: 'Chase Bank' },
  { key: 'wellsfargo', value: 'Wells Fargo', content: 'Wells Fargo' },
  { key: 'citibank', value: 'Citibank', content: 'Citibank' },
  { key: 'truist', value: 'Truist Bank', content: 'Truist Bank' },
  { key: 'capitalone', value: 'Capital One', content: 'Capital One' },
]

const searcher = new FuzzySearch(banks, ['key', 'value'])

export default function UsaBankAccountForm() {
  const {
    formState: { errors },
    register,
    control,
  } = useFormContext<Account>()

  const validate = useAccountAliasValidate()

  const [query, setQuery] = React.useState('')

  const filteredBanks = query === '' ? banks : searcher.search(query)

  return (
    <div className="max-w-prose">
      <hr className="my-8" />
      <p className="text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <TextField
        className="mt-4 sm:mt-8"
        label="Account alias"
        helpText="Any friendly name to help you identify the account"
        required
        errors={errors}
        {...register('alias', { required, validate })}
      />

      <div className="gap-8 sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Account number"
          required
          errors={errors}
          {...register('accountNumber', { required })}
        />
        <TextField
          className="mt-2"
          label="Routing number"
          required
          errors={errors}
          {...register('routingNumber', { required })}
        />
      </div>

      <div className="gap-8 sm:grid lg:grid-cols-2 ">
        <Controller
          name="bankName"
          control={control}
          rules={{ required }}
          render={({ field, fieldState }) => (
            <div className="mt-2">
              <label className="text-sm text-slate-700">
                Bank name{' '}
                <span className="relative -left-0.5 top-px text-base leading-none text-red-500">
                  *
                </span>
              </label>
              <Combobox
                onClose={() => setQuery('')}
                immediate
                onChange={field.onChange}
                value={field.value || ''}
              >
                <div className="group relative">
                  <ChevronDownIcon
                    className="absolute right-3 top-3 block h-4 w-4 text-slate-600 group-hover:text-sky-700 "
                    aria-hidden="true"
                  />
                  <ComboboxInput
                    autoCorrect="off"
                    autoComplete="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    onBlur={field.onBlur}
                    ref={field.ref}
                    onChange={(event) => setQuery(event.target.value)}
                    className={classNames(
                      fieldState.error ? 'bg-red-50' : 'bg-slate-50',
                      'mt-0 block w-full cursor-pointer border-0 border-b-2 border-gray-200 px-1 pb-0.5 pt-1.5 text-xl focus:border-blue-500 focus:ring-0 disabled:border-gray-100 disabled:text-slate-400'
                    )}
                  />
                </div>
                <ComboboxOptions
                  anchor="bottom"
                  transition
                  className="w-[var(--input-width)] origin-top border bg-white shadow transition duration-200 ease-out empty:invisible data-[closed]:scale-95 data-[closed]:opacity-0"
                >
                  {query.length > 0 && (
                    <ComboboxOption
                      className="group cursor-pointer px-2 py-2  data-[focus]:bg-blue-100"
                      value={query}
                    >
                      Add new: <span className="font-semibold">{query}</span>
                    </ComboboxOption>
                  )}
                  {filteredBanks.map((bank) => (
                    <ComboboxOption
                      key={bank.key}
                      value={bank.value}
                      className="group flex cursor-pointer items-baseline justify-between px-2 py-2 data-[focus]:bg-blue-100"
                    >
                      {bank.content}
                      <CheckIcon className="invisible size-5 group-data-[selected]:visible" />
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              </Combobox>
              <div className="mt-1 min-h-[20px] text-sm text-red-600">
                {fieldState.error?.message}
              </div>
            </div>
          )}
        />
      </div>

      <TextField
        className="mt-2"
        label="Bank address"
        multiline
        rows={4}
        required
        errors={errors}
        {...register('bankAddress', { required })}
      />

      <fieldset className="mb-2 mt-1 rounded-md border px-4 pb-2 pt-5">
        <div className="text-xs font-semibold uppercase text-slate-600 [letter-spacing:0.5px]">
          Beneficiary details
        </div>

        <TextField
          className="mt-2"
          label="Name on the account"
          helpText="We must send payment only to accounts under your name"
          required
          errors={errors}
          {...register('beneficiary', { required })}
        />

        <div className="text-sm text-slate-700">Beneficiary Address</div>

        <div className="gap-4 sm:grid lg:grid-cols-4">
          <TextField
            className="col-span-2 mt-2"
            label="Street name"
            required
            errors={errors}
            {...register('beneficiaryStreetName', { required })}
          />
          <TextField
            className="mt-2"
            label="House/building number"
            required
            errors={errors}
            {...register('beneficiaryHouseNumber', { required })}
          />
          <TextField
            className="mt-2"
            label="Apartment"
            {...register('beneficiaryApartment')}
          />
        </div>

        <div className="gap-4 sm:grid lg:grid-cols-4">
          <TextField
            className="col-span-2 mt-2"
            label="City"
            required
            errors={errors}
            {...register('beneficiaryCity', { required })}
          />
          <TextField
            className="mt-2"
            label="State or Province"
            required
            errors={errors}
            {...register('beneficiaryStateOrProvince', { required })}
          />
          <TextField
            className="mt-2"
            label="ZIP code"
            required
            errors={errors}
            {...register('beneficiaryZipCode', { required })}
          />
        </div>

        <div className="gap-4 sm:grid lg:grid-cols-2 ">
          <TextField
            className="mt-2"
            label="Country"
            required
            errors={errors}
            {...register('beneficiaryCountry', { required })}
          />
        </div>
      </fieldset>
    </div>
  )
}
