import type { SWRConfiguration } from 'swr'
import useSWR from 'swr'

import { PageError, TimeOffRequestPreview } from '../types'
import { getTimeOffRequestPreview } from '../api/timeoffApi'

export default function useTimeOffRequestPreview(
  dates: [string, boolean][],
  options?: SWRConfiguration
) {
  const params = dates.map(([timestamp]) => parseInt(timestamp, 10))
  return useSWR<TimeOffRequestPreview[], PageError>(
    ['timeoff-request-preview', params],
    params.length ? () => getTimeOffRequestPreview(params) : null,
    options
  )
}
