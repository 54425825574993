import { ExclaimationCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'
import groupBy from 'ramda/src/groupBy'
import getYear from 'date-fns/getYear'
import isPast from 'date-fns/isPast'
import format from 'date-fns/format'

import { classNames } from '../utils'
import useHolidays from '../hooks/useHolidays'
import Spinner from '../components/Spinner'

export default function HolidaysPage() {
  const holidays = useHolidays()

  // group holidays by year so we can render the list in sections
  const years = groupBy((holiday) => {
    return getYear(holiday.date).toString()
  }, holidays.data || [])

  return (
    <div className="max-w-prose">
      <p className="text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      {holidays.data &&
        (holidays.data.length ? (
          Object.keys(years)
            .sort((a, b) => Number(a) - Number(b))
            .map((year) => {
              return (
                <section className="mt-6" key={year}>
                  <h2 className="px-3 text-xl font-semibold text-red-500 sm:text-2xl">
                    {year}
                  </h2>
                  <ol className="mt-2 space-y-1">
                    {(years[year] || [])
                      .sort((a, b) => (a.ndate || 0) - (b.ndate || 0))
                      .map((holiday) => {
                        return (
                          <li
                            className={classNames(
                              isPast(holiday.date)
                                ? 'bg-slate-50 opacity-40'
                                : 'bg-slate-100',
                              'rounded-md px-3 py-3 sm:flex sm:gap-2'
                            )}
                            key={`${holiday.date}-${holiday.name}`}
                          >
                            <div className="grow text-base text-slate-700 sm:font-semibold">
                              {format(holiday.date, 'MMMM do')}
                            </div>
                            <div className="text-sm text-slate-500 sm:text-base">
                              {holiday.name}
                            </div>
                          </li>
                        )
                      })}
                  </ol>
                </section>
              )
            })
        ) : (
          <p className="mt-6 italic text-slate-400">
            Weird... it seems your team has no holidays.
          </p>
        ))}

      {!holidays.data && !holidays.error && (
        <Spinner className="mx-auto my-12" />
      )}
      {holidays.error && !holidays.data && (
        <div className="my-3 text-sm text-red-800">
          <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
          {holidays.error.message}
        </div>
      )}
    </div>
  )
}
