import {
  ShieldExclamationIcon,
  ShieldCheckIcon,
  GlobeAltIcon,
  ClockIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'
import format from 'date-fns/format'

import UsaBankIcon from './UsaBankIcon'
import { Account } from '../types'

interface Props {
  account: Account
}

export default function AccountDetails({ account }: Props) {
  return (
    <div className="mt-4">
      <div className="space-y-6 text-sm text-slate-500">
        <div className="grid grid-cols-2 gap-2">
          <p>
            <span className="block font-semibold text-slate-700">
              Account Alias
            </span>
            {account.alias}
          </p>
          <div>
            <span className="block font-semibold text-slate-700">Status</span>
            {
              {
                Rejected: (
                  <ShieldExclamationIcon className="relative top-[-1px] -ml-1 mr-1 inline-block h-8 w-5 text-red-500" />
                ),
                Approved: (
                  <ShieldCheckIcon className="relative top-[-1px] -ml-1 mr-1 inline-block h-8 w-5 text-green-500" />
                ),
                'Waiting for approval': (
                  <ClockIcon className="relative top-[-1px] -my-2 -ml-1 mr-1 inline-block h-8 w-5 text-amber-500" />
                ),
              }[account.status]
            }
            {account.status}
            {account.status === 'Rejected' && account.reason && (
              <div className="rounded border border-red-200 bg-red-100 px-1.5 py-1.5 text-xs text-red-900">
                {account.reason}
              </div>
            )}
          </div>
        </div>

        {account.type !== 'payoneer' && (
          <p>
            <span className="block font-semibold text-slate-700">
              Account Type
            </span>
            {
              {
                usa: (
                  <>
                    <UsaBankIcon className="relative top-[-0.5px] -ml-0.5 mr-0.5 inline-block w-4" />
                    USA bank account
                  </>
                ),
                payoneer: 'Payoneer',
                international: (
                  <>
                    <GlobeAltIcon className="relative top-[-1px] -my-2 -ml-1 mr-1 inline-block w-5" />
                    International bank account
                  </>
                ),
              }[account.type]
            }
          </p>
        )}

        {account.type !== 'payoneer' && <hr className="mt-4" />}

        {account.type !== 'payoneer' && (
          <div className="grid grid-cols-2 gap-2">
            <p>
              <span className="block font-semibold text-slate-700">
                Account Number
              </span>
              <span className="font-mono">{account.accountNumber}</span>
            </p>

            {account.type === 'international' && account.bankSwift && (
              <p>
                <span className="block font-semibold text-slate-700">
                  Bank SWIFT
                </span>
                <span className="font-mono">{account.bankSwift}</span>
              </p>
            )}

            {account.type === 'usa' && account.routingNumber && (
              <p>
                <span className="block font-semibold text-slate-700">
                  Routing Number
                </span>
                <span className="font-mono">{account.routingNumber}</span>
              </p>
            )}
          </div>
        )}

        {account.type === 'international' && (
          <div className="grid grid-cols-2 gap-2">
            {account.accountCountry && (
              <p>
                <span className="block font-semibold text-slate-700">
                  Account Country
                </span>
                {account.accountCountry}
              </p>
            )}

            {account.accountCurrency && (
              <p>
                <span className="block font-semibold text-slate-700">
                  Currency
                </span>
                {account.accountCurrency}
              </p>
            )}
          </div>
        )}

        {account.type !== 'payoneer' && <hr className="mt-4" />}

        {account.type !== 'payoneer' && (
          <div className="grid grid-cols-2 gap-2">
            <p>
              <span className="block font-semibold text-slate-700">
                Bank Name
              </span>
              {account.bankName}
            </p>

            {account.bankAddress && (
              <p>
                <span className="block font-semibold text-slate-700">
                  Bank Address
                </span>
                <span className="whitespace-pre">{account.bankAddress}</span>
              </p>
            )}
          </div>
        )}

        {account.type !== 'payoneer' && <hr className="mt-4" />}

        {account.type !== 'payoneer' && (
          <div className="grid grid-cols-2 gap-2">
            <p>
              <span className="block font-semibold text-slate-700">
                Beneficiary
              </span>
              {account.beneficiary}
            </p>

            <p>
              <span className="block font-semibold text-slate-700">
                Address
              </span>
              <div>
                {account.beneficiaryHouseNumber} {account.beneficiaryStreetName}{' '}
                {account.beneficiaryApartment}
              </div>
              <div>
                {account.beneficiaryCity} {account.beneficiaryStateOrProvince}{' '}
                {account.beneficiaryZipCode}
              </div>
              <div>{account.beneficiaryCountry}</div>
            </p>
          </div>
        )}
      </div>

      <hr className="mt-8" />

      <div className="mt-4 grid grid-cols-2 gap-2 text-xs text-slate-500">
        <p>
          <span className="block">Created on</span>
          {format(account!.createdOn, 'PPppp')}
        </p>
        <p>
          <span className="block">Last updated</span>
          {format(account!.lastUpdateOn, 'PPppp')}
        </p>
      </div>
    </div>
  )
}
