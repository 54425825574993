import { differenceInBusinessDays } from 'date-fns'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import * as React from 'react'

import { PayrollTransaction } from '../types'
import useAccounts from '../hooks/useAccounts'
import Tooltip from './Tooltip'
import {
  isBankOfAmerica,
  isInternational,
  isOtherUsa,
  isPayoneer,
} from '../utils'

interface Props {
  tx: PayrollTransaction
}

export default function PaymentDebitEta({ tx }: Props) {
  const accounts = useAccounts()
  const account = accounts.data?.find((a) => a._id === tx.bankAccountId)

  if (
    tx.status !== 'Completed' ||
    tx.type !== 'debit' ||
    !tx.completedOn ||
    !account
  ) {
    return null
  }

  const [delayInBusinessDays, helpText] = isPayoneer(account)
    ? [1, 'Transfers to Payoneer are usually instant']
    : isBankOfAmerica(account)
    ? [1, 'Transfers to Bank of America usually take 1 business day']
    : isOtherUsa(account)
    ? [1, 'Transfers to USA accounts usually take 1 business day']
    : isInternational(account)
    ? [3, 'International transfers usually take 3 business days']
    : [0, '']

  const diffToNow = differenceInBusinessDays(Date.now(), tx.completedOn)

  // Avoid displaying ETA information for old enough transfers
  // that we assume should have arrived by now
  if (diffToNow > delayInBusinessDays) return null

  return (
    <Tooltip content={helpText}>
      <div className="mt-0.5 cursor-help text-xs italic text-slate-500">
        ETA: {delayInBusinessDays} business{' '}
        {delayInBusinessDays === 1 ? 'day' : 'days'}{' '}
        <QuestionMarkCircleIcon className="relative -top-px left-0.5 inline-block h-3 w-3" />
      </div>
    </Tooltip>
  )
}
