import { rest } from 'msw'

import { TimeOffBalance, Holiday, TimeOffRequest } from '../types'
import { apiBaseUrl } from '../config'

const fakeRequests: TimeOffRequest[] = [
  {
    _id: '1',
    dates: [new Date('2022-10-30')],
    comments: 'all right',
    status: 'Canceled',
    createdOn: 1661258795744,
  },
  {
    _id: '2',
    dates: [new Date('2022-10-30')],
    comments: 'all right',
    status: 'Waiting for approval',
    createdOn: 1661258795748,
  },
  {
    _id: '3',
    dates: [new Date('2022-11-03')],
    comments: 'all right',
    status: 'Waiting for approval',
    createdOn: 1661258795747,
  },
  {
    _id: '4',
    dates: [new Date('2022-09-29')],
    comments: 'all right',
    status: 'Waiting for approval',
    createdOn: 1661258795746,
  },
  {
    _id: '5',
    dates: [
      new Date('2022-09-24'),
      new Date('2022-09-25'),
      new Date('2022-09-26'),
    ],
    comments: 'all right',
    status: 'Waiting for approval',
    createdOn: 1661258795745,
  },
]

const fakeHolidays: Holiday[] = [
  {
    _id: 'a',
    date: new Date('2022-10-12'),
    year: 2022,
    name: 'Cultural Diversity Day',
  },
  {
    _id: 'b',
    date: new Date('2022-11-20'),
    year: 2022,
    name: 'National Sovereignty Day',
  },
  {
    _id: 'c',
    date: new Date('2022-12-08'),
    year: 2022,
    name: 'Virgin Mary Day',
  },
  {
    _id: 'd',
    date: new Date('2022-12-25'),
    year: 2022,
    name: 'Christmas',
  },
  {
    _id: 'e',
    date: new Date('2023-01-01'),
    year: 2023,
    name: 'New Year',
  },
]

const getTimeOffBalanceHandler = rest.get<null, {}, TimeOffBalance>(
  apiBaseUrl + '/pto/balance',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(
      ctx.status(200),
      ctx.json({
        available: 20,
        renewalDate: new Date('2024-02-01T02:59:59.999Z'),
        accrued: 15,
        consumed: 0,
        carriedOverFromLastYear: 5,
      }),
      ctx.delay(500)
    )
  }
)

const getTimeOffRequestsHandler = rest.get<null, {}, TimeOffRequest[]>(
  apiBaseUrl + '/timeoff-requests',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakeRequests), ctx.delay(500))
  }
)

const getHolidaysHandler = rest.get<null, {}, Holiday[]>(
  apiBaseUrl + '/holidays',
  (req, res, ctx) => {
    // return res(ctx.status(404), ctx.delay(1500))
    return res(ctx.status(200), ctx.json(fakeHolidays), ctx.delay(500))
  }
)

export const handlers = [
  getTimeOffRequestsHandler,
  getTimeOffBalanceHandler,
  getHolidaysHandler,
]
