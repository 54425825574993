export enum EnvironmentMode {
  local = 'local',
  development = 'development',
  production = 'production',
}

const getString = (
  name: string,
  allowedValues: string[] = [],
  warnIfMissing: boolean = true
): string | null => {
  const value =
    typeof process.env[name] === 'string' ? process.env[name]!.trim() : null
  if (warnIfMissing && !value) {
    console.warn(`Environment variable ${name} is not present`)
  }
  if (
    warnIfMissing &&
    allowedValues.length &&
    !!value &&
    !allowedValues.includes(value)
  ) {
    console.warn(
      `Environment variable ${name} with value (${value}) is not allowed, should be one of (${allowedValues.join(
        ', '
      )})`
    )
  }
  return value
}

// type ENVS = {
//   [key in `${EnvironmentMode}`]: string
// }

// const getStringByEnv = (envs: ENVS): string => {
//   const nodeEnv = getString('REACT_APP_STAGE', [
//     'local',
//     'development',
//     'production',
//   ]) as keyof ENVS
//   return envs[nodeEnv]
// }

/**
 * Environmental variables used throughout the application.
 */
export const ENV_VARS = {
  /**
   * Current environment mode: development | testing | staging | production
   */
  NODE_ENV: getString('NODE_ENV'),
  /**
   * SWR: Revalidate on focus
   */
  REACT_APP_REVALIDATE_ON_FOCUS: getString(
    'REACT_APP_REVALIDATE_ON_FOCUS',
    [],
    false
  ),
  /**
   * Google authenticator Sign in URL
   */
  REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID: getString(
    'REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID'
  ),
  /**
   * If true will use a mocked API instead of the contractors API base URL
   */
  REACT_APP_USE_MOCKED_API: getString('REACT_APP_USE_MOCKED_API'),
  /**
   * Public URL used to load assets (images, etc)
   */
  PUBLIC_URL: getString('PUBLIC_URL', [], false),
  /**
   * Contractors API base URL
   */
  REACT_APP_API_BASE_URL: getString('REACT_APP_API_BASE_URL'),
  // @TODO: Development build has the NODE_ENV as 'production', we need to update that environment variable before using this method
  // REACT_APP_API_BASE_URL: getStringByEnv({
  //   local: 'http://localhost:8080/v1/contractor',
  //   development: 'https://api-dev.southteam.io/v1/contractor',
  //   production: 'https://api.southteams.io/v1/contractor',
  // }),
  /**
   * Auth API base URL
   */
  REACT_APP_AUTH_API_BASE_URL: getString('REACT_APP_AUTH_API_BASE_URL'),
  // @TODO: Development build has the NODE_ENV as 'production', we need to update that environment variable before using this method
  // REACT_APP_AUTH_API_BASE_URL: getStringByEnv({
  //   local: 'http://localhost:8080',
  //   development: 'https://api-dev.southteam.io',
  //   production: 'https://api.southteams.io',
  // }),
}

export const inProd = () => {
  return ENV_VARS.NODE_ENV === EnvironmentMode.production
}
