import {
  ShieldExclamationIcon,
  ChevronDownIcon,
  CheckIcon,
  ClockIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { Menu, Transition } from '@headlessui/react'
import { NavLink } from 'react-router-dom'
import * as React from 'react'

import { classNames } from '../utils'
import { Account } from '../types'
import Tooltip from './Tooltip'

export default function AccountsCombo(props: {
  disabledOption?: Account
  selected?: Account
  accounts: Account[]
  onSelect(selectedAccount: Account): void
}) {
  const selectedAlias =
    props.selected?.alias === 'payoneer' ? 'Payoneer' : props.selected?.alias
  return (
    <Menu as="div" className="relative w-48">
      <Menu.Button className="block w-full border-0 border-b-2 border-gray-200 bg-white bg-[length:1.25em] px-2 pb-0.5 pt-1.5 text-lg outline-none ring-0 hover:bg-slate-100 focus:ring-0 focus-visible:border-b-blue-600 sm:bg-slate-50">
        <div className="flex items-center justify-between">
          <span className="sr-only">Open accounts menu</span>
          {selectedAlias || (
            <span className="text-lg italic leading-7 text-slate-500">
              Select an account
            </span>
          )}
          <ChevronDownIcon
            className="ml-4 block h-4 w-4 text-slate-400"
            aria-hidden="true"
          />
        </div>
      </Menu.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        as={React.Fragment}
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {props.accounts.map((account) => {
            const isInUse = account._id === props.disabledOption?._id
            const isDisabled =
              isInUse ||
              account.status === 'Waiting for approval' ||
              account.status === 'Rejected'
            return (
              <Menu.Item disabled={isDisabled} key={account._id}>
                {({ active }) => (
                  <button
                    className={classNames(
                      active && 'bg-gray-100',
                      isDisabled
                        ? 'cursor-not-allowed text-slate-400'
                        : 'text-gray-700',
                      'flex w-full justify-between px-4 py-2 text-left text-sm'
                    )}
                    onClick={() => props.onSelect(account)}
                    type="button"
                  >
                    {account.alias === 'payoneer' ? 'Payoneer' : account.alias}{' '}
                    {isInUse && <span>(in use)</span>}
                    {props.selected?._id === account._id && (
                      <CheckIcon className="h-5 w-5 text-orange-500" />
                    )}
                    {account.status === 'Waiting for approval' && (
                      <Tooltip
                        content={
                          <span className="whitespace-nowrap">
                            Waiting for approval
                          </span>
                        }
                        placement="right"
                      >
                        <ClockIcon className="h-5 w-5 text-orange-200" />
                      </Tooltip>
                    )}
                    {account.status === 'Rejected' && (
                      <Tooltip
                        content={
                          <span className="whitespace-nowrap">
                            Rejected account
                            {account.reason ? `: ${account.reason}` : ''}
                          </span>
                        }
                        placement="right"
                      >
                        <ShieldExclamationIcon className="h-5 w-5 cursor-help text-red-300" />
                      </Tooltip>
                    )}
                  </button>
                )}
              </Menu.Item>
            )
          })}
          <Menu.Item>
            {({ active }) => (
              <NavLink
                to="/payments/accounts/new"
                state={{
                  returnToLabel: 'Payment setup',
                  returnToPath: '/payments/setup',
                }}
                className={() =>
                  classNames(
                    active ? 'bg-gray-100' : '',
                    'flex border-t-2 border-solid border-slate-100 px-4 py-2 text-sm'
                  )
                }
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5 text-slate-500" />
                Add account...
              </NavLink>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
