import { FieldErrors, UseFormRegisterReturn } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'
import * as React from 'react'

type TextareaProps = JSX.IntrinsicElements['textarea']
type InputProps = JSX.IntrinsicElements['input']

type Props = {
  helpText?: React.ReactNode
  labelClassName?: string
  label: React.ReactNode
  multiline?: boolean
  required?: boolean
  errors?: FieldErrors
} & UseFormRegisterReturn &
  TextareaProps &
  InputProps

const TextField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    label,
    errors,
    className,
    labelClassName,
    helpText,
    multiline,
    required,
    ...other
  } = props

  const hasErrors = errors && !!errors[props.name]

  return (
    <div className={className}>
      <label
        htmlFor={other.name}
        className={
          'whitespace-nowrap text-sm text-slate-700' +
          (labelClassName ? ' ' + labelClassName : '')
        }
      >
        {label}{' '}
        {required && (
          <span className="relative -left-0.5 top-px text-base leading-none text-red-500">
            *
          </span>
        )}
      </label>

      {multiline ? (
        <textarea
          className={`${
            hasErrors ? 'bg-red-50' : 'bg-slate-50'
          }  mt-0 block w-full border-0 border-b-2 border-gray-200 px-1 py-0.5 text-xl focus:border-blue-500 focus:ring-0 disabled:border-gray-100 disabled:text-slate-400`}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          {...other}
          ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
          /* disable 1Password */
          data-1p-ignore
        />
      ) : (
        <input
          aria-invalid
          className={`${
            hasErrors ? 'bg-red-50' : 'bg-slate-50'
          } mt-0 block w-full border-0 border-b-2 border-gray-200 px-1 pb-0.5 pt-1.5 text-xl focus:border-blue-500 focus:ring-0 disabled:border-gray-100 disabled:text-slate-400`}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck="false"
          type="text"
          {...other}
          ref={ref}
          /* disable 1Password */
          data-1p-ignore
        />
      )}
      {helpText && (
        <div className="mt-1 text-sm text-slate-500">{helpText}</div>
      )}

      <div className="mt-1 min-h-[20px] text-sm text-red-600">
        <ErrorMessage errors={errors} name={other.name} />
      </div>
    </div>
  )
})

TextField.displayName = 'TextField'

export default TextField
