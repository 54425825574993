import { useFormContext } from 'react-hook-form'
import * as React from 'react'

import useAccountAliasValidate from '../hooks/useAccountAliasValidate'
import { Account } from '../types'
import TextField from './TextField'

const required = 'This field is required'

export default function InternationalBankAccountForm() {
  const {
    register,
    formState: { errors },
  } = useFormContext<Account>()

  const validate = useAccountAliasValidate()

  return (
    <div className="max-w-prose">
      <hr className="my-8" />
      <p className="text-slate-500">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusnt
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum.
      </p>
      <TextField
        className="mt-4 sm:mt-8"
        label="Account alias"
        helpText="Any friendly name to help you identify the account"
        required
        errors={errors}
        {...register('alias', { required, validate })}
      />

      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Account number"
          required
          errors={errors}
          {...register('accountNumber', { required })}
        />
      </div>

      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Country of the account"
          required
          errors={errors}
          {...register('accountCountry', { required })}
        />
        <TextField
          className="mt-2"
          label="Currency on the account"
          required
          errors={errors}
          {...register('accountCurrency', { required })}
        />
      </div>

      <div className="gap-8  sm:grid lg:grid-cols-2 ">
        <TextField
          className="mt-2"
          label="Bank name"
          required
          errors={errors}
          {...register('bankName', { required })}
        />
        <TextField
          className="mt-2"
          label="Bank SWIFT code"
          required
          errors={errors}
          {...register('bankSwift', { required })}
        />
      </div>

      <TextField
        className="mt-2"
        label="Bank address"
        required
        errors={errors}
        multiline
        rows={4}
        {...register('bankAddress', { required })}
      />

      <fieldset className="mb-2 mt-1 rounded-md border px-4 pb-2 pt-5">
        <div className="text-xs font-semibold uppercase text-slate-600 [letter-spacing:0.5px]">
          Beneficiary details
        </div>

        <TextField
          className="mt-2"
          label="Name on the account"
          helpText="We must send payment only to accounts under your name"
          required
          errors={errors}
          {...register('beneficiary', { required })}
        />

        <div className="text-sm text-slate-700">Beneficiary Address</div>

        <div className="gap-4 sm:grid lg:grid-cols-4">
          <TextField
            className="col-span-2 mt-2"
            label="Street name"
            required
            errors={errors}
            {...register('beneficiaryStreetName', { required })}
          />
          <TextField
            className="mt-2"
            label="House/building number"
            required
            errors={errors}
            {...register('beneficiaryHouseNumber', { required })}
          />
          <TextField
            className="mt-2"
            label="Apartment"
            {...register('beneficiaryApartment')}
          />
        </div>

        <div className="gap-4 sm:grid lg:grid-cols-4">
          <TextField
            className="col-span-2 mt-2"
            label="City"
            required
            errors={errors}
            {...register('beneficiaryCity', { required })}
          />
          <TextField
            className="mt-2"
            label="State or Province"
            required
            errors={errors}
            {...register('beneficiaryStateOrProvince', { required })}
          />
          <TextField
            className="mt-2"
            label="ZIP code"
            required
            errors={errors}
            {...register('beneficiaryZipCode', { required })}
          />
        </div>

        <div className="gap-4 sm:grid lg:grid-cols-2 ">
          <TextField
            className="mt-2"
            label="Country"
            required
            errors={errors}
            {...register('beneficiaryCountry', { required })}
          />
        </div>
      </fieldset>
    </div>
  )
}
