import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ChevronLeftIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import { ErrorMessage } from '@hookform/error-message'
import * as React from 'react'

import InternationalBankAccountForm from '../components/InternationalBankAccountForm'
import UsaBankAccountForm from '../components/UsaBankAccountForm'
import { createAccount } from '../api/accountsApi'
import PayoneerTutorial from '../components/PayoneerTutorial'
import usePayoneerLink from '../hooks/usePayoneerLink'
import { classNames } from '../utils'
import { useToasts } from '../components/ToastsProvider'
import PayoneerLogo from '../components/PayoneerLogo'
import UsaBankIcon from '../components/UsaBankIcon'
import useAccounts from '../hooks/useAccounts'
import { Account } from '../types'
import Tooltip from '../components/Tooltip'
import Spinner from '../components/Spinner'

type OptionProps = {
  children: React.ReactNode
} & JSX.IntrinsicElements['input']

const Option = React.forwardRef<HTMLInputElement, OptionProps>(
  ({ children, ...other }, ref) => {
    return (
      <label className="w-full">
        <input
          className="peer absolute opacity-0"
          type="radio"
          id="payoneer"
          {...other}
          ref={ref}
        />
        <span className="flex h-full grow cursor-pointer items-center justify-center rounded-md border-2 border-slate-200 bg-slate-100 px-2 py-4 text-center text-base ring-offset-2 peer-checked:border-sky-500 peer-checked:bg-sky-100 peer-focus-within:ring-2 peer-hover:bg-sky-50 peer-disabled:cursor-not-allowed peer-disabled:opacity-40 md:text-xs xl:text-base">
          {children}
        </span>
      </label>
    )
  }
)

Option.displayName = 'Option'

export type FormData = Account

export default function CreateAccountPage() {
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const payoneerLink = usePayoneerLink()
  const { addToast } = useToasts()
  const accounts = useAccounts()
  const navigate = useNavigate()

  const location = useLocation()

  const locationState = location.state as {
    returnToLabel: string
    returnToPath: string
  } | null

  const methods = useForm<Account>({
    shouldUnregister: true,
    defaultValues: location.state || {},
  })

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setError(null)
    setIsSubmitting(true)
    accounts.mutate(
      async (optimisticallyUpdatedResult) => {
        await createAccount(data)
          .then(() => {
            addToast('Account added', { variant: 'success' })
            navigate('/payments/accounts')
          })
          .catch((err) => setError(err.message))
          .finally(() => setIsSubmitting(false))
        return optimisticallyUpdatedResult
      },
      {
        optimisticData(currenctAccounts: Account[] = []) {
          return [
            {
              ...data,
              lastUpdateOn: Date.now(),
              status: 'Waiting for approval',
              _id: 'optimistic',
            },
            ...currenctAccounts,
          ]
        },
        rollbackOnError: true,
      }
    )
  }

  const hasPayoneerAccount = (accounts.data || []).some(
    (account) => account.type === 'payoneer'
  )

  const selectedType = methods.watch('type')

  return (
    <div className="pb-4">
      <h1 className="text-3xl">New Account</h1>
      <div className="my-6">
        <Link
          className="-ml-1 text-sm text-sky-600 underline underline-offset-1 outline-none ring-offset-2 hover:text-sky-700 focus-visible:ring-2"
          to={locationState?.returnToPath || '/payments/accounts'}
        >
          <ChevronLeftIcon className="mr-1 inline-block w-4 align-text-bottom" />
          Back to {locationState?.returnToLabel || 'Accounts'}
        </Link>
      </div>

      <p className="text-sm text-slate-600">Choose the account type</p>

      <FormProvider {...methods}>
        <form className="mb-10 mt-4" onSubmit={methods.handleSubmit(onSubmit)}>
          {!accounts.data && !accounts.error && (
            <Spinner className="mx-auto my-12" />
          )}
          <div
            className={classNames(
              !accounts.data && 'invisible',
              'grid grid-cols-1 gap-3 md:grid-cols-3 md:gap-2 lg:gap-4 xl:gap-6'
            )}
          >
            {hasPayoneerAccount ? (
              <Tooltip
                content={
                  <div className="w-[140px]">
                    Only one Payoneer account is supported
                  </div>
                }
              >
                <div className="flex w-full cursor-not-allowed items-center justify-center rounded-md border-2 border-slate-200 bg-slate-100 px-2 py-4 opacity-40">
                  <PayoneerLogo className="ml-0.5 inline-block h-16 w-36 md:w-32" />
                </div>
              </Tooltip>
            ) : (
              <Option
                value="payoneer"
                disabled={isSubmitting}
                {...methods.register('type', {
                  required: 'Choose a payment method',
                })}
              >
                <PayoneerLogo className="ml-0.5 inline-block h-16 w-36 md:w-32" />
              </Option>
            )}
            <Option
              value="usa"
              disabled={isSubmitting}
              {...methods.register('type', {
                required: 'Choose a payment method',
              })}
            >
              <div className="flex flex-col items-center gap-0.5">
                <UsaBankIcon className="w-8 text-slate-500 lg:w-10" />
                <div className="text-base text-slate-600 md:text-xs lg:text-base">
                  USA bank account
                </div>
              </div>
            </Option>
            <Option
              value="international"
              disabled={isSubmitting}
              {...methods.register('type', {
                required: 'Choose a payment method',
              })}
            >
              <div className="flex flex-col items-center gap-0.5">
                <GlobeAltIcon className="w-8 text-slate-500 lg:w-10" />
                <div className="text-base text-slate-600 md:text-xs lg:text-base">
                  International bank account
                </div>
              </div>
            </Option>
          </div>

          <div className="mt-2 text-sm text-red-600">
            <ErrorMessage errors={methods.formState.errors} name="type" />
          </div>

          {selectedType === 'payoneer' && <PayoneerTutorial />}

          {selectedType === 'usa' && <UsaBankAccountForm />}

          {selectedType === 'international' && <InternationalBankAccountForm />}

          {error && (
            <div className="mt-4 text-sm text-red-600">
              Server Error: {error}
            </div>
          )}

          {selectedType && (
            <button
              className="text-md mt-8 block w-full min-w-[180px] rounded-md bg-sky-500 px-8 py-3 font-semibold text-white shadow outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2 disabled:bg-sky-300 md:w-auto"
              disabled={
                isSubmitting ||
                (selectedType === 'payoneer' && !payoneerLink.data?.url)
              }
              type="submit"
            >
              {isSubmitting && (
                <span className="relative top-0.5 mr-2 inline-block">
                  <Spinner reverse size="small" />
                </span>
              )}
              {isSubmitting
                ? 'Saving...'
                : selectedType === 'payoneer'
                ? 'I received the e-mail'
                : 'Save'}
            </button>
          )}
        </form>
      </FormProvider>
    </div>
  )
}
