import { ExclaimationTriangleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import * as React from 'react'

import { removeAccount } from '../api/accountsApi'
import { Account } from '../types'
import { useSWRConfig } from 'swr'
import { useToasts } from './ToastsProvider'
import Spinner from './Spinner'

interface Props {
  account: Account
}

export default function AccountRejectionMessage(props: Props) {
  const { account } = props

  const { addToast } = useToasts()
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()

  const [isRemoving, setIsRemoving] = React.useState<
    'from-scratch' | 'prefill' | null
  >(null)

  const handleRemove = async (shouldPrefill?: boolean) => {
    setIsRemoving(shouldPrefill ? 'prefill' : 'from-scratch')
    return removeAccount(account._id)
      .then(() =>
        Promise.all([
          mutate('accounts'),
          navigate('/payments/accounts/new', {
            state: shouldPrefill ? account : {},
          }),
        ])
      )
      .catch((err) =>
        addToast(err.message, { variant: 'danger', autoCloseDelay: 5000 })
      )
      .finally(() => setIsRemoving(null))
  }

  return (
    <div className="-mt-1 mb-3 rounded-md border border-red-200 bg-red-100 px-3 py-4 text-sm text-red-900">
      <ExclaimationTriangleIcon className="relative -top-px -ml-px inline-block h-5 w-5" />{' '}
      <span className="font-semibold">Rejected:</span> {account.reason}
      <div className="mt-4 border-t border-red-800/20 pt-4 text-sm text-red-900/70">
        Delete this account and create a new one. You can start fresh or
        pre-fill with current details.
      </div>
      <div className="flex gap-4 pb-1 pt-3">
        <button
          disabled={isRemoving !== null}
          type="button"
          className="rounded border border-red-800/50 px-3 py-2 text-xs font-semibold text-red-800/70 disabled:opacity-50"
          onClick={() => handleRemove()}
        >
          {isRemoving === 'from-scratch' && (
            <Spinner
              className="relative -left-1 -top-px inline-block"
              size="small"
            />
          )}
          Delete & Start from Scratch
        </button>

        <button
          disabled={isRemoving !== null}
          type="button"
          className="rounded border border-red-800/50 px-3 py-2 text-xs font-semibold text-red-800/70 disabled:opacity-50"
          onClick={() => handleRemove(true)}
        >
          {isRemoving === 'prefill' && (
            <Spinner
              className="relative -left-1 -top-px inline-block"
              size="small"
            />
          )}
          Delete & Pre-fill New Account
        </button>
      </div>
    </div>
  )
}
