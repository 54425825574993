import type { SWRConfiguration } from 'swr'
import isFuture from 'date-fns/isFuture'
import useSWR from 'swr'

import { getHolidays } from '../api/timeoffApi'
import { Holiday } from '../types'

export default function useUpcomingHolidays(options?: SWRConfiguration) {
  const { data, ...other } = useSWR<Holiday[], Error>(
    'holidays',
    getHolidays,
    options
  )
  return {
    data: data
      ?.filter((holiday) => isFuture(holiday.date))
      .sort((a, b) => (a.ndate || 0) - (b.ndate || 0)),
    ...other,
  }
}
