import { format } from 'date-fns'
import { z } from 'zod'

import {
  timeOffRequestPreviewSchema,
  timeOffTransactionSchema,
  TimeOffRequestPreview,
  timeOffBalanceSchema,
  timeOffRequestSchema,
  timeOffSummarySchema,
  TimeOffTransaction,
  TimeOffBalance,
  TimeOffRequest,
  TimeOffSummary,
  holidaySchema,
  Holiday,
} from '../types'
import { apiBaseUrl, tokenStorageKey } from '../config'
import { checkAuth, validateResponse } from '../utils'

export async function getTimeOffBalance(): Promise<TimeOffBalance> {
  return fetch(apiBaseUrl + '/pto/balance', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  }).then(validateResponse(timeOffBalanceSchema))
}

export async function getTimeOffTransactions(): Promise<TimeOffTransaction[]> {
  return fetch(apiBaseUrl + '/timeoff-transactions', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(timeOffTransactionSchema)))
}

export async function getTimeOffRequests(): Promise<TimeOffRequest[]> {
  return fetch(apiBaseUrl + '/timeoff-requests', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(timeOffRequestSchema)))
}

export async function cancelTimeOffRequest(
  id: TimeOffRequest['_id']
): Promise<Response> {
  return fetch(apiBaseUrl + '/timeoff-requests/' + id, {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
    method: 'DELETE',
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) throw new Error((await res.json()).message)
      return res
    })
}

export async function requestTimeOff(body: {
  dates: string[]
  comments: string
}): Promise<TimeOffRequest> {
  return fetch(apiBaseUrl + '/timeoff-requests', {
    method: 'POST',
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then(checkAuth)
    .then(async (res) => {
      if (!res.ok) throw new Error((await res.json()).message)
      return res.json()
    })
}

export async function getTimeOffRequestPreview(
  dates: number[]
): Promise<TimeOffRequestPreview[]> {
  const url = new URL(apiBaseUrl + '/pto/preview')
  for (const date of dates) {
    url.searchParams.append('dates', format(date, 'yyyy-MM-dd'))
  }
  return fetch(url.toString(), {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(timeOffRequestPreviewSchema)))
}

export async function getTimeOffSummary(): Promise<TimeOffSummary[]> {
  return fetch(apiBaseUrl + '/pto/summary', {
    headers: { Authorization: window.localStorage[tokenStorageKey] },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(timeOffSummarySchema)))
}

export async function getHolidays(): Promise<Holiday[]> {
  return fetch(apiBaseUrl + '/holidays', {
    headers: {
      Authorization: window.localStorage[tokenStorageKey],
      'astor-sortby': 'ndate',
      'astor-sortby-dir': '1',
    },
  })
    .then(checkAuth)
    .then(validateResponse(z.array(holidaySchema)))
}
