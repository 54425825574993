import {
  QuestionMarkCircleIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/24/outline'
import * as React from 'react'

import eachDayOfInterval from 'date-fns/eachDayOfInterval'
import startOfMonth from 'date-fns/startOfMonth'
import isSameYear from 'date-fns/isSameYear'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import addMonths from 'date-fns/addMonths'
import isSameDay from 'date-fns/isSameDay'
import isTodayFn from 'date-fns/isToday'
import isFuture from 'date-fns/isFuture'
import getTime from 'date-fns/getTime'
import format from 'date-fns/format'
import getDay from 'date-fns/getDay'
import isPast from 'date-fns/isPast'
import sub from 'date-fns/sub'
import add from 'date-fns/add'

import CalendarCell, { getCellStatus } from '../components/CalendarCell'
import { classNames, currencyFormat } from '../utils'
import { MAX_PTO_DAYS_PER_REQUEST } from '../config'
import useTimeOffRequestPreview from '../hooks/useTimeOffRequestPreview'
import useTimeOffTransactions from '../hooks/useTimeOffTransactions'
import TimeOffRequestDialog from '../components/TimeOffRequestDialog'
import { requestTimeOff } from '../api/timeoffApi'
import useTimeOffBalance from '../hooks/useTimeOffBalance'
import { useToasts } from '../components/ToastsProvider'
import useHolidays from '../hooks/useHolidays'
import LoadingBar from '../components/LoadingBar'
import Spinner from '../components/Spinner'
import Tooltip from '../components/Tooltip'

/**
 * Returns an array of all the days in a calendar page
 *
 * This includes days from the previous and the following months to fill in the
 * remaining cells in a square grid.
 */
function getCalendarPageCells(targetDate: Date): Date[] {
  const firstDayOfMonth = startOfMonth(targetDate)
  const lastDayOfMonth = endOfMonth(targetDate)

  // how many days from the previous month we need to fill the start of the grid
  const startOffset = getDay(firstDayOfMonth)
  // how many days of the next month we need to fill the end of the grid
  const endOffset = 6 - getDay(lastDayOfMonth)

  return eachDayOfInterval({
    start: sub(firstDayOfMonth, { days: startOffset }),
    end: add(lastDayOfMonth, { days: endOffset }),
  })
}

export default function TimeOffPage() {
  const { addToast } = useToasts()
  const transactions = useTimeOffTransactions({
    onError() {
      addToast(`Your time-off transactions couldn't be retrieved`, {
        variant: 'danger',
      })
    },
  })
  const holidays = useHolidays({
    onError() {
      addToast(`Holidays couldn't be retrieved`, { variant: 'danger' })
    },
  })
  const timeOffBalance = useTimeOffBalance()

  // init the calendar centering on the current month (actually the current day)
  const [targetDate, setTargetDate] = React.useState(new Date())

  // to create prev and next links we simply substract/add one month
  const prevMonth = subMonths(targetDate, 1)
  const nextMonth = addMonths(targetDate, 1)

  // prevent navigation to past months
  const canNavigateToPrev = !isPast(prevMonth) || isTodayFn(prevMonth)

  const [currentSelection, setCurrentSelection] = React.useState<
    Record<number, boolean>
  >({})

  const selectionEntries = Object.entries(currentSelection).filter(
    ([_, isSelected]) => !!isSelected
  )

  const preview = useTimeOffRequestPreview(selectionEntries, {
    onError(err) {
      addToast(
        err.status === 400
          ? err.info.message
          : `PTO coverage preview couldn't be retrieved`,
        { variant: 'warning' }
      )
    },
    // handy to keep previous preview results visible while a new preview is
    // being retrieved
    keepPreviousData: true,
  })

  const selectedCount = selectionEntries.length

  const [confirmationDialog, setConfirmationDialog] = React.useState<
    'paid' | null
  >(null)

  const handleTimeOffRequest = async (comments: string) => {
    const dates = selectionEntries.map(([timestamp]) => {
      return format(parseInt(timestamp, 10), 'yyyy-MM-dd')
    })
    return requestTimeOff({ dates, comments })
      .then(() => transactions.mutate())
      .then(() => setConfirmationDialog(null))
      .then(() => {
        // give some time for closing dialog animation to settle
        setTimeout(() => {
          addToast('Time-off requested', { variant: 'success' })
        }, 1000)
      })
      .catch((err) => {
        addToast(err.message, { variant: 'danger' })
      })
  }

  const hasFutureRequests = (transactions.data || []).some((t) => {
    return (
      t.type === 'debit' &&
      (t.status === 'Approved' || t.status === 'Waiting for approval') &&
      isFuture(t.date)
    )
  })

  // Total potential cost of the current selection (in case some days are not
  // covered by PTO policy). Defaults to 0
  const totalCost =
    preview.data?.reduce((sum, { dayValue }) => sum + (dayValue || 0), 0) || 0

  return (
    <div className="pb-12">
      <p className="mt-2 max-w-prose text-slate-600">
        Lorem ipsum dolor sit amet, consectetur adipisicing sint occaecat dent,
        sunt in culpa qui officia deserunt mollit anim id est laborum.
      </p>

      <div className="mt-4 flex items-baseline gap-1 text-base">
        Available <span className="sm:hidden">PTO</span>
        <span className="hidden sm:inline">Paid Time-Off</span> to date:{' '}
        {timeOffBalance.data && (
          <span className="ml-1 whitespace-nowrap font-semibold text-sky-500">
            {timeOffBalance.data.available}{' '}
            {timeOffBalance.data.available === 1 ? 'day' : 'days'}
          </span>
        )}
        {!timeOffBalance.data && !timeOffBalance.error && (
          <div className="ml-3 flex h-6 items-center self-center">
            <Spinner size="small" />
          </div>
        )}
        {timeOffBalance.error && !timeOffBalance.data && (
          <div className="ml-2 text-sm text-red-600">
            {timeOffBalance.error.message}
          </div>
        )}
      </div>

      <nav className="-mx-4 mb-4 mt-4 flex items-baseline justify-between gap-1 md:mx-0">
        <button
          disabled={!canNavigateToPrev}
          className="rounded text-sky-500 outline-none ring-offset-4 hover:text-sky-700 focus-visible:ring-2 disabled:opacity-40"
          onClick={() => setTargetDate(prevMonth)}
        >
          <ChevronLeftIcon className="mr-1 inline-block w-4 align-text-bottom" />
          {format(
            prevMonth,
            // if previous month is in a different year, make it explicit
            isSameYear(prevMonth, targetDate) ? 'MMM' : 'MMM yyyy'
          )}
        </button>
        <span className="whitespace-nowrap text-xl font-semibold text-slate-600 md:text-2xl">
          <span className="sm:hidden">{format(targetDate, 'MMM yyyy')}</span>
          <span className="hidden sm:inline">
            {format(targetDate, 'MMMM yyyy')}
          </span>
        </span>
        <button
          className="rounded text-sky-500 outline-none ring-offset-4 hover:text-sky-700 focus-visible:ring-2 disabled:opacity-40"
          onClick={() => setTargetDate(nextMonth)}
        >
          {format(
            nextMonth,
            // if next month is in a different year, make it explicit
            isSameYear(nextMonth, targetDate) ? 'MMM' : 'MMM yyyy'
          )}
          <ChevronRightIcon className="ml-1 inline-block w-4 align-text-bottom" />
        </button>
      </nav>

      <div className="relative top-[34px] -mx-4 -mb-0.5 md:mx-0">
        <LoadingBar isLoading={transactions.isLoading || holidays.isLoading} />
      </div>

      <div
        className={classNames(
          (transactions.isLoading || holidays.isLoading) && 'animate-pulse',
          '-mx-4 grid grid-cols-7 gap-0 md:mx-0'
        )}
      >
        <div className="p-2 text-center text-sm text-slate-600">
          S<span className="sr-only sm:not-sr-only">unday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          M<span className="sr-only sm:not-sr-only">onday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          T<span className="sr-only sm:not-sr-only">uesday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          W<span className="sr-only sm:not-sr-only">ednesday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          T<span className="sr-only sm:not-sr-only">hursday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          F<span className="sr-only sm:not-sr-only">riday</span>
        </div>
        <div className="p-2 text-center text-sm text-slate-600">
          S<span className="sr-only sm:not-sr-only">aturday</span>
        </div>

        {getCalendarPageCells(targetDate).map((cellDate, i) => {
          const dayTimestamp = getTime(cellDate)
          const isSelected = !!currentSelection[dayTimestamp]

          const datePreviewData = preview.data?.find((item) =>
            isSameDay(item.date, cellDate)
          )
          const isDeductible = datePreviewData?.coveredByPtoPolicy === false
          const selectionStatus = isSelected
            ? isDeductible
              ? 'not-covered'
              : 'covered'
            : 'unselected'
          const isDisabled =
            transactions.isLoading ||
            holidays.isLoading ||
            hasFutureRequests ||
            (selectionStatus === 'unselected' &&
              selectedCount >= MAX_PTO_DAYS_PER_REQUEST)
          const dateTransactions = (transactions.data || []).filter((t) =>
            isSameDay(t.date, cellDate)
          )
          const cellStatus = getCellStatus({
            selectionEntries,
            transactions: dateTransactions,
            day: cellDate,
          })
          const holiday = holidays.data?.find((h) =>
            isSameDay(h.date, cellDate)
          )
          return (
            <CalendarCell
              datePreviewData={datePreviewData}
              holiday={holiday}
              selectionStatus={selectionStatus}
              transactions={dateTransactions}
              isDisabled={isDisabled}
              onClick={() =>
                setCurrentSelection((prev) => ({
                  ...prev,
                  [dayTimestamp]: !prev[dayTimestamp],
                }))
              }
              status={cellStatus}
              day={cellDate}
              key={cellDate.toISOString()}
            />
          )
        })}
      </div>

      {hasFutureRequests && selectedCount === 0 && (
        <div
          className="-mx-4 mt-[1px] flex min-h-[60px] items-center rounded-md rounded-t-none bg-orange-50 px-5 py-3 text-sm text-amber-700 shadow-md outline outline-1 outline-orange-200 md:mx-0"
          key={hasFutureRequests.toString()}
        >
          Time-off requests are disabled due to pending future requests{' '}
          <Tooltip content="To submit a new request, wait until the scheduled days off have been consumed or cancel any pending requests.">
            <QuestionMarkCircleIcon className="relative ml-1 inline-block h-4 w-4 cursor-help" />
          </Tooltip>
        </div>
      )}

      {selectedCount > 0 && (
        <div
          className="-mx-4 -mt-0 flex items-baseline gap-3 rounded-md rounded-t-none bg-slate-50 py-3 pl-4  pr-3 shadow-md outline outline-1 outline-slate-100 md:mx-0"
          key={selectedCount}
        >
          <div className="grow whitespace-nowrap text-sm text-slate-900">
            {selectedCount} {selectedCount === 1 ? 'day' : 'days'} selected
            {totalCost > 0 && (
              <span className="ml-2">
                &ndash;
                <span className="ml-1 font-semibold text-red-600">
                  Total cost: {currencyFormat.format(totalCost)}
                </span>
              </span>
            )}
            <button
              className="whitespace-nowrap rounded-md px-3 py-2 text-sm text-sky-600 underline outline-none hover:text-sky-700 focus-visible:ring-2"
              type="button"
              onClick={() => setCurrentSelection({})}
            >
              Clear
            </button>
          </div>

          <button
            className="whitespace-nowrap rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white outline-none ring-offset-2 hover:bg-sky-600 focus-visible:ring-2  disabled:bg-sky-300"
            onClick={() => setConfirmationDialog('paid')}
            type="button"
          >
            Request <span className="hidden sm:inline">Time-Off</span>
          </button>
        </div>
      )}

      <TimeOffRequestDialog
        afterLeave={() => setCurrentSelection({})}
        onSubmit={handleTimeOffRequest}
        onClose={() => setConfirmationDialog(null)}
        preview={preview}
        isOpen={confirmationDialog !== null}
      />
    </div>
  )
}
