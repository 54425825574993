import * as React from 'react'
import { Link } from 'react-router-dom'

import usePaymentsBalance from '../hooks/usePaymentsBalance'
import PaymentSetupForm from '../components/PaymentSetupForm'
import usePaymentSetup from '../hooks/usePaymentSetup'
import useAccounts from '../hooks/useAccounts'
import ErrorBox from '../components/ErrorBox'
import Spinner from '../components/Spinner'

const CalculatingMessage = () => (
  <aside className="-mx-3 mt-6 max-w-prose rounded-md bg-slate-100 p-3">
    <h2 className="mb-3 font-bold text-slate-600">
      We&apos;re calculating your next payment
    </h2>
    <p className="text-sm">
      This will be based on how many days you worked in the current period, so
      the final amount will be available near the end of the month.
    </p>
    <p className="mt-4 text-sm">
      In the meantime you can{' '}
      <Link
        className="text-sky-600 underline underline-offset-1 hover:text-sky-700"
        to="accounts"
      >
        setup your accounts
      </Link>
      , though!
    </p>
  </aside>
)

const NoAccountsMessage = () => (
  <aside className="-mx-3 mt-6 max-w-prose rounded-md bg-slate-100 p-3">
    <h2 className="mb-3 font-bold text-slate-600">No accounts yet</h2>
    <p className="text-sm">
      We need some details in order to be able to send you payments.{' '}
      <Link
        className="text-sky-600 underline underline-offset-1 hover:text-sky-700"
        to="/payments/accounts/new"
        state={{
          returnToLabel: 'Payment setup',
          returnToPath: '/payments/setup',
        }}
      >
        Add your first account
      </Link>
      .
    </p>
  </aside>
)

export default function PaymentSetupPage() {
  const paymentSetup = usePaymentSetup()
  const accounts = useAccounts()
  const balance = usePaymentsBalance()

  const error = paymentSetup.error || accounts.error || balance.error

  const isLoading =
    (paymentSetup.data === undefined ||
      balance.data === undefined ||
      accounts.data === undefined) &&
    !error

  const nextPaymentAmount =
    typeof balance.data?.balance === 'number' ? balance.data.balance : null

  return (
    <div className="max-w-2xl">
      {isLoading ? (
        <Spinner className="mx-auto my-12" />
      ) : accounts.data?.length === 0 ? (
        <NoAccountsMessage />
      ) : nextPaymentAmount === null ? (
        <CalculatingMessage />
      ) : (
        <PaymentSetupForm
          nextPaymentAmount={nextPaymentAmount}
          paymentSetup={paymentSetup.data!}
          accounts={accounts.data!}
        />
      )}

      {error && <ErrorBox className="mt-6">{error.message}</ErrorBox>}
    </div>
  )
}
