import {
  ExclaimationTriangleIcon,
  ExclaimationCircleIcon,
} from '@heroicons/react/24/outline'
import intervalToDuration from 'date-fns/intervalToDuration'
import formatDuration from 'date-fns/formatDuration'
import * as React from 'react'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'

import useUpcomingHolidays from '../hooks/useUpcomingHolidays'
import usePaymentsBalance from '../hooks/usePaymentsBalance'
import { currencyFormat } from '../utils'
import useTimeOffBalance from '../hooks/useTimeOffBalance'
import DownloadFileLink from '../components/DownloadFileLink'
import usePaymentSetup from '../hooks/usePaymentSetup'
import useDocuments from '../hooks/useDocuments'
import useProfile from '../hooks/useProfile'
import SlackIcon from '../components/SlackIcon'
import ErrorBox from '../components/ErrorBox'
import Spinner from '../components/Spinner'
import useTeam from '../hooks/useTeam'
import useUser from '../hooks/useUser'

export default function DashboardPage() {
  const timeOffBalance = useTimeOffBalance()
  const paymentSetup = usePaymentSetup({ shouldRetryOnError: false })
  const documents = useDocuments()
  const holidays = useUpcomingHolidays()
  const balance = usePaymentsBalance()
  const profile = useProfile()
  const team = useTeam()
  const user = useUser()

  const [maxHolidaysCount, setMaxHolidaysCount] = React.useState<
    number | undefined
  >(3)

  const firstName = user.data?.fullName.split(' ')[0]

  const renderedHolidays = holidays.data?.slice(0, maxHolidaysCount)

  return (
    <div className="pb-32">
      <header>
        <p className="text-3xl">Hi{firstName ? `, ${firstName}!` : '!'}</p>
        {profile.data?.startingDate ? (
          <p className="mt-3 text-base text-slate-600 md:text-sm">
            You&apos;ve been with us for{' '}
            {formatDuration(
              intervalToDuration({
                start: new Date(profile.data.startingDate),
                end: new Date(),
              }),
              { format: ['years', 'months', 'days'], delimiter: '|' }
            )
              .split('|')
              .map((segment, i, arr) => (
                <span key={segment}>
                  {i > 0 ? (i === arr.length - 1 ? ' and ' : ', ') : ''}
                  <strong>{segment}</strong>
                </span>
              ))}
          </p>
        ) : (
          <div className="mt-3 animate-pulse">
            <div className="h-5 w-60 rounded-lg bg-slate-100"></div>
          </div>
        )}
      </header>

      <div className="-mx-4 mt-4 grid grid-cols-2 gap-1 md:gap-4 lg:grid-cols-3 ">
        <div>
          <div className="rounded-md bg-white p-4">
            <h2 className="text-xs font-semibold text-slate-500 sm:text-sm">
              Available PTO to date
            </h2>
            {timeOffBalance.data && (
              <div className="mb-1 mt-2 text-2xl font-normal text-slate-700 sm:text-3xl">
                {timeOffBalance.data.available}{' '}
                {timeOffBalance.data.available === 1 ? 'day' : 'days'}
              </div>
            )}
            {!timeOffBalance.data && !timeOffBalance.error && (
              <div className="my-4 text-2xl">
                <Spinner size="small" />
              </div>
            )}
            {timeOffBalance.error && !timeOffBalance.data && (
              <div className="my-3 text-sm text-red-800">
                <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
                {timeOffBalance.error.message}
              </div>
            )}
            <Link
              className="text-sm text-sky-600 underline underline-offset-2 outline-none ring-offset-4 hover:text-sky-700 focus-visible:ring-2 sm:text-xs"
              to="/timeoff/request"
            >
              Request a PTO
            </Link>
          </div>
        </div>

        <div className="order-last col-span-2 lg:col-span-1">
          <div className="rounded-md bg-white p-4">
            <h2 className="text-xl lg:text-sm lg:font-semibold lg:text-slate-500">
              Upcoming holidays
            </h2>
            {holidays.data &&
              (holidays.data.length && renderedHolidays?.length ? (
                <div className="ml-5 mt-3">
                  <ol className="list-disc space-y-3 marker:text-red-500 lg:space-y-2">
                    {renderedHolidays.map((holiday) => {
                      return (
                        <li key={`${holiday.date}-${holiday.name}`}>
                          <div className="text-base lg:text-sm">
                            {format(holiday.date, 'MMMM do')}
                          </div>
                          <div className="text-sm text-slate-500 lg:text-xs">
                            {holiday.name}
                          </div>
                        </li>
                      )
                    })}
                  </ol>

                  {renderedHolidays?.length < holidays.data.length && (
                    <button
                      className="mt-2 text-sm text-sky-600 underline underline-offset-2 outline-none ring-offset-4 hover:text-sky-700 focus-visible:ring-2 sm:text-xs"
                      type="button"
                      onClick={() => setMaxHolidaysCount(undefined)}
                    >
                      View more
                    </button>
                  )}
                </div>
              ) : (
                <p className="mt-4 italic text-slate-400">
                  No holidays in the near future
                </p>
              ))}
            {!holidays.data && !holidays.error && (
              <div className="my-4 text-2xl">
                <Spinner size="small" />
              </div>
            )}
            {holidays.error && !holidays.data && (
              <div className="my-3 text-sm text-red-800">
                <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
                {holidays.error.message}
              </div>
            )}
          </div>
        </div>

        <div className="order-0 lg:order-last">
          {typeof balance.data?.balance === 'number' && (
            <div className="rounded-md bg-slate-100 p-4">
              <h2 className="text-xs font-semibold text-slate-600 sm:text-sm">
                Your balance
              </h2>
              <div className="mt-1 flex gap-1 text-2xl font-semibold text-slate-600 sm:text-3xl">
                {currencyFormat.format(balance.data.balance || 0)}
              </div>
              {!paymentSetup.isLoading && paymentSetup.data === null && (
                <div className="mt-2 flex justify-between gap-2 rounded bg-red-500 px-2 py-2 text-xs text-white">
                  <ExclaimationTriangleIcon className="relative -top-px inline-block h-5 w-5 shrink-0" />{' '}
                  <div className="font-semibold leading-snug">
                    Payment Setup not configured yet. You won&apos;t be able to
                    receive payments until you do so!{' '}
                    <Link className="ml-1 underline" to="/payments/setup">
                      Configure
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
          {!balance.data && !balance.error && (
            <Spinner className="mx-auto my-8" />
          )}
          {balance.error && !balance.data && (
            <div className="my-3 text-sm text-red-800">
              <ExclaimationCircleIcon className="-ml-0.5 mr-0.5 inline-block h-4 w-4 align-middle" />{' '}
              {balance.error.message}
            </div>
          )}
        </div>
      </div>

      <section className="mt-6">
        <div className="flex items-center justify-between">
          <h2 className="text-xl">
            Your team{' '}
            <span className="hidden sm:inline">
              {team.data && `at ${team.data.name}`}
            </span>
          </h2>
          {team.data?.slack && (
            <a
              className="flex items-center whitespace-nowrap text-base text-sky-600 underline underline-offset-2 outline-none hover:text-sky-700 focus-visible:ring-2 md:pr-2"
              href={team.data.slack}
            >
              <SlackIcon className="-mr-1 mt-0.5 inline-block w-10" />{' '}
              <span className="hidden sm:inline">Join them on&nbsp;</span> Slack
            </a>
          )}
        </div>
        {team.data &&
          (team.data.members.length ? (
            <ul className="-mx-2 mt-4 grid gap-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3">
              {team.data.members.map((teammate) => {
                return (
                  <li
                    className="flex items-start rounded-md bg-slate-50 px-2 py-2"
                    key={`${teammate.name}-${teammate.southEmail}`}
                  >
                    {teammate.picture ? (
                      <img
                        referrerPolicy="no-referrer"
                        className="h-10 w-10 rounded-full"
                        src={teammate.picture}
                        alt=""
                      />
                    ) : (
                      <svg
                        className="h-10 w-10 rounded-full"
                        viewBox="0 0 128 128"
                        xmlns="http://www.w3.org/2000/svg"
                        role="img"
                      >
                        <circle
                          className="fill-slate-400"
                          cx="64"
                          cy="64"
                          r="64"
                        />
                        <path
                          fill="#fff"
                          d="M103,102.1388 C93.094,111.92 79.3504,118 64.1638,118 C48.8056,118 34.9294,111.768 25,101.7892 L25,95.2 C25,86.8096 31.981,80 40.6,80 L87.4,80 C96.019,80 103,86.8096 103,95.2 L103,102.1388 Z"
                        />
                        <path
                          fill="#fff"
                          d="M63.9961647,24 C51.2938136,24 41,34.2938136 41,46.9961647 C41,59.7061864 51.2938136,70 63.9961647,70 C76.6985159,70 87,59.7061864 87,46.9961647 C87,34.2938136 76.6985159,24 63.9961647,24"
                        />
                      </svg>
                    )}
                    <div className="ml-2">
                      <div className="text-base text-slate-800">
                        {teammate.name}{' '}
                        {teammate.name === user.data?.fullName && (
                          <span className="relative -top-px ml-0.5  rounded border border-red-600 px-[2.5px] py-px text-[10px] font-semibold uppercase leading-none text-red-500 [letter-spacing:0.5px]">
                            YOU
                          </span>
                        )}
                      </div>
                      <div className="text-xs text-slate-500">
                        {teammate.southEmail || (
                          <span className="italic">
                            &ndash;No Southteams e-mail&ndash;
                          </span>
                        )}
                      </div>
                      {teammate.position && (
                        <div className="mt-0.5 inline-block rounded border border-slate-300 px-1 text-[11px] text-slate-500">
                          {teammate.position}
                        </div>
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          ) : (
            <p className="mt-6 italic text-slate-400">No teammates yet!</p>
          ))}
        {!team.data && !team.error && (
          <div className="max-w-prose">
            <Spinner className="mx-auto my-3" />
          </div>
        )}
        {team.error && !team.data && (
          <ErrorBox className="mt-6">{team.error.message}</ErrorBox>
        )}
      </section>

      {(documents.data || []).length !== 0 && (
        <section className="mt-12 max-w-prose md:mt-16">
          <h2 className="text-xl">Important Documents</h2>
          {documents.data &&
            (documents.data.length ? (
              <ul className="mt-6 flex flex-col items-baseline gap-6 md:mt-3 lg:flex-row lg:gap-20">
                {documents.data.map((doc) => {
                  return (
                    <li key={doc.link + doc.title}>
                      <DownloadFileLink fileName={doc.title} url={doc.link}>
                        {doc.title}
                      </DownloadFileLink>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <p className="mt-6 italic text-slate-400">No documents</p>
            ))}
          {!documents.data && !documents.error && (
            <Spinner className="mx-auto my-3" />
          )}
          {documents.error && !documents.data && (
            <ErrorBox className="mt-6">{documents.error.message}</ErrorBox>
          )}
        </section>
      )}
    </div>
  )
}
